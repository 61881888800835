body {
  overflow-x: hidden;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
/*
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}*/



.App-link {
  color: #61dafb;
}

.cabecera {
  background-image: url("../src/img/cabecerapubli.webp");
  min-height: 600px;
  max-height: 800px;
}
.cabeceraVial {
  background-image: url("../src/img/cabeceraVial.webp");
  min-height: 600px;
  max-height: 800px;
}
.oscurecido {
  background-color: rgba(0, 0, 0, 0.452);
  border-radius: 5%;
}
.carrusel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-item img {
  object-fit: cover;
  width: 100%;
  height: 300px;
}



.primario {
    background-color: red;
      border-radius: 30px;
  padding-left: 100px;
}
.secundario {
  background-color: red;
 border-radius: 30px;
  padding-right: 100px;
}

h2{
  margin-top: 40px !important;
  margin-bottom: 40px !important;
  ;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
